import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "url(#a)",
      d: "M23.297 1.732h-3.08q.017-.512.017-1.029A.703.703 0 0 0 19.531 0H4.47a.703.703 0 0 0-.703.703q0 .517.017 1.03H.703A.703.703 0 0 0 0 2.434c0 3.15.823 6.123 2.318 8.37 1.478 2.221 3.447 3.495 5.573 3.62q.726.787 1.523 1.278v3.125h-1.18a2.59 2.59 0 0 0-2.586 2.586v1.18h-.05a.703.703 0 0 0 0 1.406h12.804a.703.703 0 1 0 0-1.406h-.05v-1.18a2.59 2.59 0 0 0-2.586-2.586h-1.18v-3.125a7.2 7.2 0 0 0 1.523-1.279c2.126-.124 4.095-1.398 5.573-3.619C23.177 8.558 24 5.585 24 2.435a.703.703 0 0 0-.703-.703M3.489 10.026C2.256 8.173 1.531 5.75 1.421 3.138h2.44c.254 3.21 1.007 6.176 2.185 8.53q.28.563.587 1.063c-1.17-.441-2.252-1.364-3.144-2.705m13.456 11.388v1.18h-9.89v-1.18c0-.65.529-1.18 1.18-1.18h7.53c.651 0 1.18.53 1.18 1.18m-3.765-2.586h-2.36v-2.514q.58.154 1.18.155.6-.001 1.18-.155zm.435-4.187a3.4 3.4 0 0 1-1.615.421 3.4 3.4 0 0 1-1.616-.421c-.55-.292-1.084-.732-1.589-1.31a1 1 0 0 0-.09-.105c-.5-.595-.971-1.326-1.401-2.187C6.01 8.455 5.265 5.057 5.18 1.406h13.64c-.085 3.651-.832 7.05-2.123 9.633-.43.861-.902 1.592-1.403 2.187a.7.7 0 0 0-.09.105c-.504.578-1.038 1.018-1.589 1.31m6.896-4.615c-.892 1.34-1.974 2.264-3.144 2.705q.306-.5.587-1.063c1.178-2.354 1.93-5.32 2.185-8.53h2.44c-.11 2.611-.835 5.035-2.068 6.888"
    }, null, -1),
    _createElementVNode("defs", null, [
      _createElementVNode("linearGradient", {
        id: "a",
        x1: "0",
        x2: "24.977",
        y1: "6.333",
        y2: "8.297",
        gradientUnits: "userSpaceOnUse"
      }, [
        _createElementVNode("stop", { "stop-color": "#EA7C4F" }),
        _createElementVNode("stop", {
          offset: "1",
          "stop-color": "#EB493D"
        })
      ])
    ], -1)
  ])))
}
export default { render: render }