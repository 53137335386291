import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "url(#a)",
      "fill-rule": "evenodd",
      d: "m21.633 11.555-1.729 1.568-.254 2.222-1.274.4 2.183 6.29-3.737-.189L13.898 24l-1.853-5.339-.412.242-.412-.242L9.368 24l-2.924-2.154-3.738.19 2.183-6.29-1.274-.4-.254-2.223-1.728-1.568.82-2.103-.82-2.103L3.36 5.78l.255-2.222 2.294-.721L7.182.934l2.408.268L11.633 0l2.043 1.202 2.408-.268 1.272 1.903 2.294.72.254 2.223 1.729 1.569-.821 2.103zM6.942 20.41l1.647 1.214 1.3-3.747-.3-.177-2.407.268-.962-1.438-1.384 3.987zm7.735 1.214 1.647-1.214 2.105.107-1.383-3.987-.962 1.438-2.408-.268-.3.177zm1.667-6.727 1.89-.594.21-1.83 1.421-1.29-.676-1.732.676-1.732-1.422-1.29-.21-1.83-1.89-.594-1.046-1.567-1.981.22-1.683-.99-1.683.99-1.98-.22-1.048 1.567-1.89.594-.21 1.83-1.421 1.29.676 1.732-.676 1.732 1.421 1.29.21 1.83 1.89.594 1.047 1.566 1.981-.22 1.683.99 1.683-.99 1.98.22zM5.687 9.45c0-3.02 2.666-5.477 5.943-5.477s5.942 2.457 5.942 5.477-2.666 5.477-5.942 5.477-5.943-2.457-5.943-5.477m1.527 0c0 2.244 1.981 4.07 4.416 4.07 2.434 0 4.415-1.826 4.415-4.07s-1.98-4.07-4.415-4.07-4.416 1.826-4.416 4.07",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("defs", null, [
      _createElementVNode("linearGradient", {
        id: "a",
        x1: "1.633",
        x2: "22.486",
        y1: "6.333",
        y2: "7.699",
        gradientUnits: "userSpaceOnUse"
      }, [
        _createElementVNode("stop", { "stop-color": "#EA7C4F" }),
        _createElementVNode("stop", {
          offset: "1",
          "stop-color": "#EB493D"
        })
      ])
    ], -1)
  ])))
}
export default { render: render }